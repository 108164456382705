






















import { mixins } from "vue-class-component"
import { Component, Vue } from "vue-property-decorator"
import { setDate, subDays, subMonths } from "date-fns/fp"
import { PageMixin } from "@/mixins"
import {
	campaignStatusDisplay,
	formatDisplay,
	Device,
	getGMVMOps,
	getOps,
} from "@/select"
import { asCurrency, asPercentage, formatDate, zeroTime } from "@/utils"

const today = zeroTime(new Date())
const start_date = subDays(8, today)
const end_date = subDays(1, today)
const defaultStart = subMonths(1, setDate(1, zeroTime(today)))
const defaultEnd = setDate(1, zeroTime(today))

@Component
export default class extends mixins(PageMixin) {
	monthrange = [defaultStart, defaultEnd]
	form = {
		use_line_end_date: true,
		start_date,
		end_date,
	}
	loading = false

	private async excelExport(email) {
		this.loading = true
		try {
			const data: any = { ...this.form, xlsx: 1 }
			if (email) data.email = 1
			const res = await getOps(data)
			if (email) {
				this.$message.success("Request received")
				this.loading = false
				return
			}
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement("a")
			link.href = url
			const now = new Date().toISOString()
			link.setAttribute("download", `SELECT Ops ${now}.xlsx`)
			document.body.appendChild(link)
			link.click()
		} catch (err) {
			this.$message.error("Error")
		} finally {
			this.loading = false
		}
	}

	async gmvmExport() {
		this.loading = true
		try {
			const res = await getGMVMOps({
				start: formatDate(this.monthrange[0]),
				end: formatDate(this.monthrange[1]),
			})
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement("a")
			link.href = url
			const now = new Date().toISOString()
			link.setAttribute("download", `SELECT Ops ${now}.csv`)
			document.body.appendChild(link)
			link.click()
		} catch (err) {
			console.error(err)
			this.$message.error("Error")
		} finally {
			this.loading = false
		}
	}
}
